.location-container {
  display: flex;
  width: 100%; }

.location-button-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  button {
    align-self: flex-end; } }
