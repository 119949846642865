//override bootstrap
.row {
  margin: 0 !important; }

body {
  margin: 0; }

.app-container {
  max-width: 1400px;
  margin: 0 auto;

  h1 {
    font-size: 1.4em;
    margin: .75em 0 .25em; }

  // enabled in dev mode by default
  &.debug {
    .clock-container {
      border: dotted 1px orange; }

    .locations-container {
      border: dotted 2px blue; }

    .location-container {
      border: dotted 2px green; }

    .weather-container {
      border: dotted 1px purple; } } }

.widget-button {
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  padding: 10px 15px;
  opacity: 0.3;
  transition: opacity 0.175s;
  align-self: flex-end;

  &:hover {
    background: rgba(0,0,0,0.05); } }

.widget-box {
  width: 25%;
  max-width: 375px;
  margin: 0 auto;
  background-color: #fefefe;
  display: flex;
  flex-flow: column nowrap; }

.widget-box::after {
  content: "";
  display: block;
  padding-bottom: 100%; }

.widget-outer-container {
  position: relative; }

.contents-container {
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: 100%; }

.app-button-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end; }
