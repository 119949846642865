// Headlines.sass

.headlines-container {
  display: grid;
  grid-template-areas: "headline" "." "buttons";
  grid-template-columns: auto;
  grid-template-rows: auto auto-fit auto;
  min-height: 250px;

  .headline {
    grid-area: headline;

    a {
      text-decoration: none;
      color: rgba(0,0,0,0.65);

      &:hover {
        color: rgba(0,0,0,0.85); } } }

  .headlines-buttons-containers {
    grid-area: buttons;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .widget-button {
      border: solid 1px rgba(0,0,0,0.5);
      border-radius: 5px; } } }
