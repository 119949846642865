.weather-container {
  position: relative; }

h3.current-temp {
  width: 100%;
  position: absolute;
  left: 0;
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5em;
  text-align: center;
  color: rgba(0,0,0,0.65);
  font-weight: normal; }

.top-tab-container {
  border-bottom: 1px solid #c7c7c7;

  h2 {
    margin: 0;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: normal; } }

.conditions-container {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    font-size: 0.9em; } }
