div.forecast-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly; }

div.forecast {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  font-size: 1.1em; }

div.weather-forecast-button-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  button {
    align-self: flex-end; } }
