// WeatherForecastChart.sass
.weather-forecast-chart-container {}

.chart-button-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  button {
    flex: 1;

    &.selected {
      border-bottom: 2px solid blue; } } }
