.clock-container {

  h4 {
    width: 100%;
    position: absolute;
    left: 0;
    margin: 0;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 2.5em;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal; } }

.clock-container::after {
  content: "";
  display: block;
  padding-bottom: 100%; }
